import React, { useEffect, useRef } from "react";

function Vidazoo({ type }) {
    
    const vidazooRef = useRef(null);
    
    useEffect(() => {
        // AY tracking script
        const ayScript= document.createElement("script");
        ayScript.innerHTML = `
            function onVidazooWidgetAdImpressionCallback(cpm, info) {
                try {
                var highestPreBidpartner = "video_unknown";
                switch (info.adType) {
                    case "ima":
                    highestPreBid_partner = "video_AdX";
                    break;
                    case "marketplace":
                    highestPreBid_partner = "video_Vidazoo";
                    break;
                    case "pre_bid":
                    highestPreBid_partner = "video" + info.bidderCode;
                    break;
                    default:
                    break;
                }
                var data = {
                    source: "video",
                    slotId: "vidazoo_video",
                    adUnitPath: "vidazoo_video",
                    highestPreBid: info.netCpm - info.adServingFee,
                    highestPreBid_partner,
                    mediaType: "vidazoo_video",
                    creative_width: info.width,
                    creative_height: info.height,
                    preBidWon: true,
                    timeToRespond: null,
                };
                window.assertive.logCustomImpression(data);
                } catch (error) {
                console.error("Couldn't log custom impression", error);
                }
          }
        `;
        document.head.appendChild(ayScript);
        
        // Vidazoo Script
        const script = document.createElement("script");
        const vidazooContainer = vidazooRef.current;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const campaignKey = urlParams.get('utm_campaign');
        const sourceKey = urlParams.get('utm_source');
        const termKey = urlParams.get('abtest');
        script.setAttribute("data-on-widget-ad-impression", "onVidazooWidgetAdImpressionCallback");
        script.src = 'https://static.vidazoo.com/basev/vwpt.js';
        script.type = 'text/javascript';
        script.setAttribute("data-ext-bi-utm_source", sourceKey);
        script.setAttribute("data-ext-bi-utm_campaign", campaignKey);
        script.setAttribute("data-ext-bi-utm_term", termKey);
        if(typeof script['async'] !== 'undefined') {script.async = true;}

        if (type === "inf") {
            script.setAttribute("data-widget-id", "62c85dd78d1dd8878665fd66");
        }
        
        if (type === "next") {
            script.setAttribute("data-widget-id", "6297256ddf1d8782b4d23140");
        }

        if (type === "facebook-template") {
            script.setAttribute("data-widget-id", "62d119b15876ed1677f084df");
        }
        
        vidazooContainer.appendChild(script);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div ref={vidazooRef} id="vidazoo-player">
        </div>
    )
}

export default Vidazoo